import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import parse from "html-react-parser";
import AdvisorImg from "../../images/area-guide/advisors.jpg";

import LocRatingMap from '../map/loc-rating-map-area-guides';
import "../AreaGuideDetails/VideoBlock/VideoBlock.scss"
import { BRANCHES_PAGE_URL } from "../common/site/constants"
import PlayVideo from '../Play/CustomVideoFullImageClick';
import NoImage from "../../images/no-image.jpg"

// markup
const VideoBlock = (props) => {


    // Negotiator Details get

    const [propItems, setPropItems] = useState(false)
    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {})
            setPropItems(data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        let url = process.env.GATSBY_STRAPI_FEED_SRC + `/branches?branch_name_contains=${props.Name}`; // TODO: base URL supposed to be from .env
        getitems(url)
    }, [])
    // Negotiator Details get

  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat'] = props.Latitude
  mapItem['lng'] = props.Longtitude
  mapItem['id'] = props.id
  const mapMarkerImageShowFlag = 0;
  mapItems.push(mapItem);

  

  return (
    <React.Fragment>
      <div className="video-block-wrap area-guide-content components--AreaGuides--DetailsContent">
        <Container>
          <Row>
            <Col xl="8" lg="7">
              <div className="block-content">
                <div className="block-text">
                  {props.Add_Content.map((item, index) => (
                    <>
                      {item.Add_Content &&
                        <>{parse(item.Add_Content)}</>
                      }
                      {item.Image &&
                        <div className="video-image image-video">
                          {item.Video_URL ?
                            <PlayVideo url={item.Video_URL} >
                              <img src={item.Image.url} alt={item.Image.alternativeText} />
                            </PlayVideo> :
                            <img src={item.Image.url} alt={item.Image.alternativeText} />
                          }
                        </div>
                      }
                    </>
                  ))}
                  {/* {parse(props.Add_Static_Content)} */}

                  {props.Latitude && props.Longtitude &&
                    <div className="locrating-map">
                      <LocRatingMap data={mapItems} />
                    </div>
                  }

                </div>
              </div>
            </Col>
            <Col xl="4" lg="5" className="block-content block-text">
              <div className="guide-details-right-block main-banner">
                <div className="first">
                  {propItems && propItems.slice(0,1).map((person, index) => (
                    <React.Fragment>
                    <div className="advisor-info">
                      <div className="advisor-img">
                        {person.sales_dept.manager.image ?
                        <img src={person.sales_dept.manager.image} alt={person.sales_dept.manager.name} />  : <img src={NoImage} alt={person.sales_dept.manager.name}/> }
                      </div>
                      <div className="advisor-contact">
                        <span className="advisor-name">{person.sales_dept.manager.name}</span>
                        <span className="advisor-designation">{person.sales_dept.manager.job_title}</span>
                        <a href={`tel:${person.sales_dept.phone}`} className="phone-number">
                          <i className="icon-phone"></i>
                          <span>{person.sales_dept.phone}</span>
                        </a>
                      </div>
                    </div>
                    <div className="advisor-info">
                      <div className="advisor-img">
                        {person.lettings_dept.manager.image ?
                        <img src={person.lettings_dept.manager.image} alt={person.lettings_dept.manager.name} /> : <img src={NoImage} alt={person.lettings_dept.manager.name}/> }
                      </div>
                      <div className="advisor-contact">
                        <span className="advisor-name">{person.lettings_dept.manager.name}</span>
                        <span className="advisor-designation">{person.lettings_dept.manager.job_title}</span>
                        <a href={`tel:${person.lettings_dept.phone}`} className="phone-number">
                          <i className="icon-phone"></i>
                          <span>{person.lettings_dept.phone}</span>
                        </a>
                      </div>
                    </div>
                    </React.Fragment>
                  ))}
                </div>

                <div className="info-text">
                  <p><b>Not local to {props.Name}?</b> We’ve got 40+ branches across Kent and surrounding areas – <Link to={BRANCHES_PAGE_URL.alias}>find your local one.</Link></p>
                </div>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default VideoBlock